<template>
  <div class="container">
    <poster :poster-list="posterList" :page-path="pagePath" :module-key="moduleKey" :token="token" :time-format="4" />
  </div>
</template>

<script>
import Poster from '../../../components/Poster/Index'

export default {
  name: "Double11MWPoster",
  components: {
    Poster
  },
  data() {
    return {
      token: this.$route.query.token,
      posterList: [
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031142612240.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031142629480.png',
          textColor: '#ffffff',
          textFontSize:26,
          tempPoster: '',
          textPoint: [430, 1203],
          wxCode: { width: 188, point: [58, 1131] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031142806499.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031142935064.png',
          textColor: '#ffffff',
          textFontSize:26,
          tempPoster: '',
          textPoint: [430, 1203],
          wxCode: { width: 188, point: [58, 1131] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031142824040.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031142901160.png',
          textColor: '#ffffff',
          textFontSize:26,
          tempPoster: '',
          textPoint: [430, 1203],
          wxCode: { width: 188, point: [58, 1131] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031142840900.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031142918933.png',
          textColor: '#ffffff',
          textFontSize:26,
          tempPoster: '',
          textPoint: [430, 1203],
          wxCode: { width: 188, point: [58, 1131] }
        }
      ],
      pagePath: this.baseHost + '/',
      moduleKey: ''
    }
  },
  created() {
    this.moduleKey = this.token ? '86fab1eb-d227-441d-9d4e-2c6c5cd6272b' : 'gzhFJScx202212'
  }
}
</script>

<style scoped>
</style>